<template>
  <div class="modern-login-wrapper">
    <div class="modern-login-container">
      <h1 class="welcome-message">欢迎登录凌空物语智能科技管理系统</h1>
      <el-form :model="user" :rules="rules" ref="userForm" class="form">
        <el-form-item prop="username">
          <el-input size="large" prefix-icon="el-icon-user-solid" v-model="user.username" placeholder="请输入用户名" class="input-field"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="large" prefix-icon="el-icon-lock" show-password v-model="user.password" placeholder="请输入密码" class="input-field"/>
        </el-form-item>
        <el-form-item prop="code">
          <div class="code-input-container">
            <el-input size="large" placeholder="请输入验证码" prefix-icon="el-icon-circle-check" style="flex: 1" v-model="user.code" @keydown.enter.native="login" class="input-field"/>
            <valid-code @update:value="getCode" class="valid-code"/>
          </div>
        </el-form-item>
        <el-form-item class="login-buttons">
          <el-button type="warning" size="large" @click="$router.push('/register')" round>立即注册</el-button>
          <el-button type="primary" size="large" icon="el-icon-circle-check" @click="login" round>立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {setRoutes} from "@/router";
import ValidCode from "@/components/ValidCode";
import webSocketService from "@/services/websocketService";

export default {
  name: "Login",
  components: {
    ValidCode
  },
  data() {
    // 验证码校验
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (value.toLowerCase() !== this.code) {
        callback(new Error('验证码输入错误!!!'))
      } else {
        callback()
      }
    }
    return {
      user: {},
      code: '',
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        code: [
          { validator: validateCode, trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.request.post("/user/login", this.user).then(res => {
            if(res.code === '200') {
              // 存储用户信息到浏览器
              localStorage.setItem("user", JSON.stringify(res.data))
              // 存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus))

              // 动态设置当前用户的路由
              setRoutes()
              webSocketService.initWebSocket(); // 初始化 WebSocket
              this.$router.push("/")
              this.$message.success("登录成功")
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    getCode(code) {
      this.code = code.toLowerCase()
    },
  }
}
</script>

<style scoped>
.modern-login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to bottom, #f8f9fa, #c036f6);
}

.modern-login-container {
  background-color: white;
  width: 460px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgb(150, 157, 150);
}

.welcome-message {
  margin-bottom: 20px;
  color: #000000;
  font-size: 24px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input-field {
  margin-bottom: 15px;
}

.login-buttons {
  display: flex;
  justify-content: space-between;
}


/* 新增验证码输入框和验证码生成器的容器样式 */
.code-input-container {
  display: flex; /* 使内部元素水平排列 */
  align-items: center; /* 垂直居中对齐 */
}
</style>
